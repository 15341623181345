import { Grid, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { Event } from '../../Model/Event';
import { UserInfo } from '../../Model/UserInfo';
import { BookingInfo } from './BookingDialog';

type Props = {
  bookingInfo?: BookingInfo;
  event?: Event;
  me?: UserInfo;
};

export const BookingDetails = (props: Props) => {
  return (
    <Grid spacing={0} columnSpacing={1} container display={'flex'} alignItems={'center'}>
      <Grid item textAlign={'right'} xs={6}>
        <Typography variant="body1">Player&nbsp;1:</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body1">
          {props.me?.FirstName} {props.me?.LastName}
        </Typography>
      </Grid>
      <Grid item textAlign={'right'} xs={6} fontWeight={'bold'}>
        <Typography variant="body1">Court:</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body1">{props.event?.CourtName}</Typography>
      </Grid>
      <Grid item textAlign={'right'} xs={6} fontWeight={'bold'}>
        <Typography variant="body1">Date:</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body1">
          {DateTime.fromISO(props.event?.Start || '').toFormat('yyyy-MM-dd')}
        </Typography>
      </Grid>
      <Grid item textAlign={'right'} xs={6} fontWeight={'bold'}>
        <Typography variant="body1">Time:</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body1">
          {DateTime.fromISO(props.event?.Start || '').toFormat('HH:mm')} -{' '}
          {DateTime.fromISO(props.event?.End || '').toFormat('HH:mm')}
        </Typography>
      </Grid>
    </Grid>
  );
};
