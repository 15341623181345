import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { Button, Divider, FormControl, Grid, IconButton, InputLabel, LinearProgress, MenuItem, Select } from '@mui/material';
import { useContext, useMemo, useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import BookingDialog from '../../Components/Booking/BookingDialog';
import { PaymentDialog } from '../../Components/Booking/PaymentDialog';
import { SiteContext } from '../../Contexts/SiteContext';
import useContainerSize from '../../Hooks/UseContainerSize';
import { Event, GroupEvents, GroupedEvents, filterEventsByDate } from '../../Model/Event';
import { useGetEventsQuery } from '../../Service/Events';
import { today } from '../../Util/Dates';
import MultiDaySlots from './MultiDaySlots';
import './SlotsPage.css';
import { BookingStatus } from '../../Model/Booking';
import { InformationalDialog } from '../../Components/Dialog/InformationalDialog';
import { useGetCourtsQuery } from '../../Service/Courts';
import { useGetKBQuery } from '../../Service/KB';
import { GameTypeTitle } from '../../Model/GameType';

const daySlotSize = 140;

const calculateNumCols = (windowSize: number) => {
  const calculated = Math.floor((windowSize - daySlotSize) / daySlotSize);
  return Math.max(1, calculated);
};

const SlotsPage = () => {
  const { width } = useContainerSize('slots-view-container');
  const numCols = useMemo(() => calculateNumCols(width), [width]);
  const [openBookingDialog, setOpenBookingDialog] = useState(false);
  const [openPaymentDialog, setOpenPaymentDialog] = useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

  const [selectedEvent, setSelectedEvent] = useState<Event | undefined>();

  const [slotsState, setSlotsState] = useState({
    date: today(),
    movedNext: true,
  });

  const handlePrevious = () => {
    setSlotsState((prev) => {
      return {
        movedNext: false,
        date: prev.date.minus({ days: numCols }),
      };
    });
  };

  const handleNext = () => {
    setSlotsState((prev) => {
      return {
        movedNext: true,
        date: prev.date.plus({ days: numCols }),
      };
    });
  };

  const siteID = useContext(SiteContext).site.id;

  const startDate = slotsState.date;
  const endDate = slotsState.date.plus({ days: numCols });

  const {
    data: events,
    isLoading: isEventsLoading,
    isSuccess,
  } = useGetEventsQuery({
    startMillis: startDate.toMillis(),
    endMillis: endDate.toMillis(),
    siteID: siteID,
  });

  const handlers = useSwipeable({
    onSwipedLeft: handleNext,
    onSwipedRight: handlePrevious,
    trackMouse: true,
    preventScrollOnSwipe: true,
  });

  const [bookedEvent, setBookedEvent] = useState<Event | undefined>();
  const handleEventBooked = (e: Event) => {

    if (!e || e.Booking?.BookingStatus === BookingStatus.Confirmed) {
      setOpenConfirmationDialog(true);
      return;
    }

    setBookedEvent(e);
    setOpenPaymentDialog(true);
  };

  const [selectedCourt, setSelectedCourt] = useState<number>(0);
  const [selectedGameType, setSelectedGameType] = useState<number>(0);

  const { data: siteCourts } = useGetCourtsQuery(siteID);
  const { data: kb } = useGetKBQuery();

  const availableCourts = useMemo(() => {
    return siteCourts?.filter((c) => {
      if (events?.some((e) => e.CourtID === c.ID)) {
        return true;
      }

      return false;
    })
  }, [siteCourts, events]);

  const availableGameTypes = useMemo(() => {
    return kb?.GameTypes.filter((gt) => {
      if (!gt || gt.AdminOnly) {
        return false;
      }
      if (events?.some((e) => e.GameTypeCosts.some((c) => c.GameTypeID === gt.ID))) {
        return true;
      }

      return false;
    })
  }, [kb, events]);


  const filtered: GroupedEvents = useMemo(() => {
    const filteredEvents = filterEventsByDate(
      // We map the events because they come in as read-only from RTK
      GroupEvents(events?.map((e) => e) || []),
      startDate,
      endDate
    );

    return Object.keys(filteredEvents).reduce((acc: any, key: string) => {
      const filtered = filteredEvents[key].filter((e) => {
        if (selectedCourt > 0 && e.CourtID !== selectedCourt) {
          return false;
        }
        if (selectedGameType > 0 && !e.GameTypeCosts.some((c) => c.GameTypeID === selectedGameType)) {
          return false;
        }
        return true;
      });

      if (filtered.length > 0) {
        acc[key] = filtered;
      }

      return acc;
    }, {});
  }, [events, startDate, endDate, selectedCourt, selectedGameType]);

  return (
    <div {...handlers}>
      {isEventsLoading && <LinearProgress />}
      <Grid container spacing={2} justifyItems={'center'} alignItems={'center'} display={'flex'}>
        <Grid item xs={12} sm={3}>
          <FormControl fullWidth>
            <InputLabel>Court</InputLabel>
            <Select
              label="Court"
              value={selectedCourt}
              onChange={(e) => setSelectedCourt(e.target.value as number)}
            >
              <MenuItem value={0}>All Courts</MenuItem>
              {availableCourts?.map((c) => (
                <MenuItem key={c.ID} value={c.ID}>{c.Name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={3}>
          <FormControl fullWidth>
            <InputLabel>Game Type</InputLabel>
            <Select
              label="Game Type"
              value={selectedGameType}
              onChange={(e) => setSelectedGameType(e.target.value as number)}
            >
              <MenuItem value={0}>All</MenuItem>
              {availableGameTypes?.map((gt) => (
                <MenuItem key={gt.ID} value={gt.ID}>{GameTypeTitle(gt)}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item textAlign={'center'} xs={12} sm={3}>
          <Button onClick={() => {
            setSelectedCourt(0);
            setSelectedGameType(0);
          }}>
            Reset
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>
      {isSuccess && (
        <Grid
          id="slots-view-container"
          container
          marginTop={2}
          justifyContent={'center'}
        >

          <Grid item marginTop={1}>
            <IconButton
              onClick={handlePrevious}
              disabled={slotsState.date <= today()}
            >
              <KeyboardArrowLeft />
            </IconButton>
          </Grid>
          <Grid item>
            <MultiDaySlots
              handleClick={(event) => {
                setSelectedEvent(event);
                setOpenBookingDialog(true);
              }}
              key={slotsState.date.toISO()}
              slots={filtered}
            />
          </Grid>
          <Grid item marginTop={1}>
            <IconButton onClick={handleNext}>
              <KeyboardArrowRight />
            </IconButton>
          </Grid>
        </Grid>
      )}
      <BookingDialog
        open={openBookingDialog}
        setOpen={setOpenBookingDialog}
        onEventBooked={handleEventBooked}
        event={selectedEvent}
      />
      <PaymentDialog
        open={openPaymentDialog}
        onClose={() => setOpenPaymentDialog(false)}
        event={bookedEvent}
      />
      <InformationalDialog
        open={openConfirmationDialog}
        setOpen={setOpenConfirmationDialog}
        title="Booking Confirmed"
        message="This booking has been made. Please check your email for confirmation."
      />
    </div>
  );
};

export default SlotsPage;
