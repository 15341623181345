export const SendResetPasswordLinkPath = '/auth/send-reset-password-link';
export const ResetPasswordPath = '/auth/reset-password';

export const ForbiddenPath = '/403';

export const LoginPath = '/auth/login';
export const LogoutPath = '/auth/logout';
export const SignupPath = '/auth/signup';

export const SendEmailConfirmationLinkPath =
  '/auth/send-email-confirmation-link';
export const ConfirmEmailPath = '/auth/confirm-email';

export const ProfilePath = '/profile';
export const UpdatePasswordPath = '/profile/update-password';

export const OpenSlotsPath = '/';
export const BookingsPath = '/bookings';
export const CalendarPath = '/calendar';

export const ManageEventTypesPath = '/manage-event-types';
export const ManageSlotTypesPath = '/manage-slot-types';
export const ManageCourtsPath = '/manage-courts';
export const ManageMembersPath = '/manage-members';
export const ManageUserRolesPath = '/manage-user-roles';
export const ManageSitePath = '/manage-site';
export const ManagePaymentsPath = '/manage-payments';
export const ReportsPath = '/reports';

export const PaymentsVivaFailurePath = '/payments/viva/failure';
export const PaymentsVivaSuccessPath = '/payments/viva/success';

export const AddSitePath = '/add-site';

export const TermsAndConditionsPath = '/terms';
export const PrivacyPolicyPath = '/privacy';
