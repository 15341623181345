import { Autocomplete, TextField } from '@mui/material';
import axios from 'axios';
import { Fragment, SyntheticEvent, useEffect, useState } from 'react';
import { LoadingStatus } from '../../Contexts/LoadingStatus';
import { User, Users } from '../../Model/User';
import { toApiUrl } from '../../Util/Api';

type Props = {
  label?: string;
  id?: any;
  includeSelf?: boolean;
  disabled?: boolean;
  siteId?: number;
  value?: User;
  onChange?: (value: User | null) => void;
};

const minRequiredChars = 3;
export const UserSearchInput = (props: Props) => {
  const [options, setOptions] = useState<User[]>();
  const [searchInput, setSearchInput] = useState<string>('');
  const [loading, setLoading] = useState(LoadingStatus.LOADED);
  function handleInputChange(v: string) {
    setLoading(LoadingStatus.LOADING);
    setSearchInput(v || '');
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      if (searchInput.length < minRequiredChars) {
        setOptions([]);
        return;
      }

      setLoading(LoadingStatus.LOADING);
      axios
        .post<Users>(toApiUrl('/users/search'), {
          Query: searchInput,
          SiteID: props.siteId,
          IncludeSelf: props.includeSelf,
        })
        .then((res) => {
          setOptions(
            res.data.items.map((u) => {
              return {
                ...u,
                SiteIDs: u.SiteIDs || [],
              };
            })
          );
          setLoading(LoadingStatus.LOADED);
        })
        .catch((e) => {
          setLoading(LoadingStatus.ERROR);
          console.error(e);
        });
    }, 1000);

    return () => clearTimeout(timer);
  }, [props.siteId, searchInput, props.includeSelf]);

  let noOptionsText = `Enter at least ${minRequiredChars} characters`;
  if (searchInput.length >= minRequiredChars) {
    if (loading === LoadingStatus.LOADING) {
      noOptionsText = 'Loading...';
    } else {
      noOptionsText = 'No users found';
    }
  }

  function handleSelectedChange(
    _event: SyntheticEvent<Element, Event>,
    value: User | null
  ): void {
    if (props.onChange) {
      props.onChange(value);
    }
  }

  const formatName = (u: User) => {
    let name = `${u.FirstName} ${u.LastName} (${u.Email})`;

    if (!props.siteId) {
      return name;
    }

    if (!u.SiteIDs || !u.SiteIDs.includes(props.siteId)) {
      return `[G] ${name}`;
    }

    return name;
  };

  return (
    <Autocomplete
      id={props.id}
      fullWidth
      filterOptions={(x) => x}
      options={options || []}
      onInputChange={(_, v) => {
        handleInputChange(v);
      }}
      disabled={props.disabled}
      onChange={handleSelectedChange}
      popupIcon={null}
      noOptionsText={noOptionsText}
      value={props.value}
      getOptionLabel={(o) => formatName(o)}
      getOptionKey={(o) => o.ID}
      isOptionEqualToValue={(o, v) => v && o.ID === v.ID}
      renderInput={(params) => (
        <TextField
          {...params}
          label={props.label}
          InputProps={{
            ...params.InputProps,
            endAdornment: <Fragment>{params.InputProps.endAdornment}</Fragment>,
          }}
        />
      )}
    />
  );
};
