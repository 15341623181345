import { Add, Delete, Edit, Search } from '@mui/icons-material';
import {
  debounce,
  Divider,
  Fab,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  LinearProgress,
  OutlinedInput,
  Stack,
  Typography,
} from '@mui/material';
import { Fragment, useContext, useState } from 'react';
import { ConfirmDialog } from '../../../Components/Dialog/ConfirmDialog';
import { SiteContext } from '../../../Contexts/SiteContext';
import { UserCredit } from '../../../Model/UserCredit';
import { useGetKBQuery } from '../../../Service/KB';
import {
  useDeleteUserCreditMutation,
  useGetSiteUserCreditsQuery,
} from '../../../Service/UserCredit';
import { AddUserCreditDialog } from './AddUserCreditDialog';
import { EditUserCreditDialog } from './EditUserCreditDialog';

export const UserCredits = () => {
  const site = useContext(SiteContext);

  const [query, setQuery] = useState<string>('');

  const { data: userCredits, isFetching } = useGetSiteUserCreditsQuery({
    SiteID: site.site.id,
    UserSearch: query,
  });

  const [deleteUserCredit] = useDeleteUserCreditMutation();

  const { data: kb } = useGetKBQuery();

  const debounceSearch = debounce((q: string) => {
    setQuery(q);
  }, 500);

  const [selectedUserCredit, setSelectedUserCredit] = useState<UserCredit>();

  const [addUserCreditDialogOpen, setAddUserCreditDialogOpen] = useState(false);

  const handleDeleteSelectedUserCredit = () => {
    try {
      if (selectedUserCredit) {
        deleteUserCredit(selectedUserCredit);
      }
    } finally {
      setDeleteDialogOpen(false);
    }
  };

  const handleOpenAddDialog = () => {
    setAddUserCreditDialogOpen(true);
  };

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);

  const getCurrencySymbol = (currencyID: number): string => {
    const currency = kb?.Currencies.find((c) => c.ID === currencyID);
    if (currency) {
      return currency.Symbol;
    }

    return '';
  };

  return (
    <>
      <Stack alignItems="center" direction="row" spacing={2} sx={{ mb: 2 }}>
        <Fab size="small" color="primary" aria-label="add">
          <Add onClick={handleOpenAddDialog} />
        </Fab>
        <Typography variant="h6">Manage User Credits</Typography>
      </Stack>
      <Grid
        container
        textAlign={'center'}
        display={'flex'}
        justifyContent={'center'}
      >
        <Grid item xs={12} sx={{ mb: 2 }}>
          <FormControl fullWidth>
            <InputLabel htmlFor="component-outlined">Search</InputLabel>
            <OutlinedInput
              id="component-outlined"
              label="Search"
              fullWidth
              onKeyUp={(e) => debounceSearch(e.currentTarget.value)}
              startAdornment={<Search color={'inherit'} />}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <Typography>Name</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography>Email</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography>Amount</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography>Actions</Typography>
        </Grid>
        <Grid item xs={12} sx={{ my: 2 }}>
          <Divider />
        </Grid>
        {isFetching && (
          <Grid item xs={12}>
            <LinearProgress />
          </Grid>
        )}
        {!isFetching &&
          userCredits?.map((uc) => (
            <Fragment key={uc.ID}>
              <Grid item xs={3}>
                <Typography>
                  {uc.FirstName} {uc.LastName}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography>{uc.Email}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography>
                  {getCurrencySymbol(uc.CurrencyID)} {uc.Amount}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Stack direction={'row'} spacing={1} justifyContent={'center'}>
                  <IconButton
                    color="default"
                    onClick={() => {
                      setSelectedUserCredit(uc);
                      setEditDialogOpen(true);
                    }}
                  >
                    <Edit />
                  </IconButton>
                  <IconButton
                    color="default"
                    onClick={() => {
                      setSelectedUserCredit(uc);
                      setDeleteDialogOpen(true);
                    }}
                  >
                    <Delete />
                  </IconButton>
                </Stack>
              </Grid>
            </Fragment>
          ))}
      </Grid>
      <AddUserCreditDialog
        open={addUserCreditDialogOpen}
        onClose={() => {
          setAddUserCreditDialogOpen(false);
        }}
      />
      <ConfirmDialog
        open={deleteDialogOpen}
        title={'Delete User Credit'}
        danger
        message={'Are you sure you want to delete this user credit?'}
        onConfirm={handleDeleteSelectedUserCredit}
        setOpen={setDeleteDialogOpen}
      />
      <EditUserCreditDialog
        open={editDialogOpen}
        onClose={() => {
          setEditDialogOpen(false);
        }}
        userCredit={selectedUserCredit!}
      />
    </>
  );
};
