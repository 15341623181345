import { DateTime } from 'luxon';

export enum BookingStatus {
  PendingPayment = 0,
  Confirmed = 1,
}

export function BookingStatusString(e?: BookingStatus): string {
  switch (e) {
    case BookingStatus.PendingPayment:
      return 'Pending Payment';
    case BookingStatus.Confirmed:
      return 'Confirmed';
    default:
      return 'Unknown';
  }
}

export interface Booking {
  ID: number;
  SlotID: number;
  GameTypeID: number;
  CourtID: number;
  StartTime: Date;
  EndTime: Date;
  BookingStatus: BookingStatus;
  EventTypeID: number;
  BookerID: number;
  Results: string;
  Cost: number;
}

export interface BookingRequest {
  SlotID: number;
  GameTypeID: number;
  SlotDate: DateTime;
  ClaimedCost: number;
  UseCredit: boolean;
  Players: number[];
  EventTypeID: number;
}
