//TODO: Replace hardcoded strings with these constants
export const UserCredits = "UserCredits";
export const Me = "Me";
export const MeRoles = "Me-Roles";
export const Events = "Events";
export const Users = "Users";
export const Sites = "Sites";
export const Courts = "Courts";
export const Bookings = "Bookings";
export const Slots = "Slots";
export const EventTypes = "EventTypes";
export const GameTypes = "GameTypes";
export const PaymentProviders = "Payment-Providers";
export const Payments = "Payments";
export const BookingRules = "BookingRules";

export const List = "List";