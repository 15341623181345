import { Fragment, useState } from 'react';

import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import {
  Controller,
  FieldValues,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import * as yup from 'yup';
import { toApiUrl } from '../../Util/Api';
import {
  Strength,
  strengthColor,
  strengthIndicator,
} from '../../Util/password-strength';

import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import {
  PrivacyPolicyPath,
  SendEmailConfirmationLinkPath,
  TermsAndConditionsPath,
} from '../../Routes';

const Signup = ({ ...others }) => {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
  const [termsChecked, setTermsChecked] = useState(false);
  const [privacyChecked, setPrivacyChecked] = useState(false);

  const [strength, setStrength] = useState(0);
  const [level, setLevel] = useState<Strength>();

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };
  const changePassword = (value: string) => {
    const temp = strengthIndicator(value);
    setStrength(temp);
    setLevel(strengthColor(temp, theme));
  };

  const schema = yup.object().shape({
    fname: yup
      .string()
      .required('First name is required')
      .min(2, 'Name is too short'),
    lname: yup
      .string()
      .required('Last name is required')
      .min(2, 'Name is too short'),
    email: yup
      .string()
      .email('Email must be a valid email address')
      .required('Email is required'),
    password: yup
      .string()
      .required('Password is required')
      .min(8, "Password can't be less than 8 characters")
      .matches(
        /^.*(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^*()& "]).*$/,
        'Password must contain at least one uppercase letter, one lowercase letter, one number and one special character'
      ),
  });

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      fname: '',
      lname: '',
      email: '',
      password: '',
    },
    resolver: yupResolver(schema),
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    setIsSubmitting(true);

    try {
      const res = await axios.post(toApiUrl('/auth/signup'), data);
      if (res.status === 201) {
        setIsCompleted(true);
        reset();
      } else {
        setError('root', { message: 'Something went wrong.' });
      }
    } catch (e: any) {
      if (e.response) {
        setError('root', { message: e.response.data.message });
      } else if (e.message) {
        setError('root', { message: e.message });
      } else {
        setError('root', { message: 'Something went wrong.' });
      }
    }

    setIsSubmitting(false);
  };

  return (
    <Grid container justifyContent="center" spacing={2}>
      <Grid item xs={12} container alignItems="center" justifyContent="center">
        <Box sx={{ mb: 2 }}>
          {!isCompleted && (
            <Typography variant="subtitle1">
              Sign up with email address
            </Typography>
          )}
          {isCompleted && (
            <Typography textAlign={'center'} variant="subtitle1">
              <p>Account created successfully!</p>
              <p>Please check your email to verify your account.</p>
              <p>
                Haven't received verification email? click{' '}
                <Link to={SendEmailConfirmationLinkPath}>here</Link> to try
                again
              </p>
            </Typography>
          )}
        </Box>
      </Grid>
      {!isCompleted && (
        <Fragment>
          <Grid item xs={12}>
            <form noValidate onSubmit={handleSubmit(onSubmit)} {...others}>
              <Grid container spacing={matchDownSM ? 0 : 2}>
                <Grid item xs={12} sm={6}>
                  <FormControl
                    fullWidth
                    margin="normal"
                    error={Boolean(errors.fname)}
                  >
                    <InputLabel htmlFor="fname">First Name</InputLabel>
                    <Controller
                      name="fname"
                      control={control}
                      render={({ field }) => (
                        <OutlinedInput
                          fullWidth
                          label="First Name"
                          disabled={isSubmitting}
                          id="fname"
                          type="text"
                          {...field}
                        />
                      )}
                    />
                    {errors.fname && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text--register"
                      >
                        {errors.fname.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl
                    fullWidth
                    margin="normal"
                    error={Boolean(errors.lname)}
                  >
                    <InputLabel htmlFor="lname">Last Name</InputLabel>
                    <Controller
                      name="lname"
                      control={control}
                      render={({ field }) => (
                        <OutlinedInput
                          fullWidth
                          disabled={isSubmitting}
                          label="Last Name"
                          id="lname"
                          type="text"
                          {...field}
                        />
                      )}
                    />
                    {errors.lname && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text--register"
                      >
                        {errors.lname.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              <FormControl
                fullWidth
                error={Boolean(errors.email)}
                margin="normal"
              >
                <InputLabel htmlFor="outlined-adornment-email-register">
                  Email Address
                </InputLabel>
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <OutlinedInput
                      label="Email Address"
                      disabled={isSubmitting}
                      type="email"
                      id="outlined-adornment-email-register"
                      {...field}
                    />
                  )}
                />
                {errors.email && (
                  <FormHelperText
                    error
                    id="standard-weight-helper-text--register"
                  >
                    {errors.email.message}
                  </FormHelperText>
                )}
              </FormControl>

              <FormControl
                fullWidth
                error={errors.password ? true : false}
                margin="normal"
              >
                <InputLabel htmlFor="outlined-adornment-password-register">
                  Password
                </InputLabel>
                <Controller
                  name="password"
                  control={control}
                  render={({ field }) => (
                    <OutlinedInput
                      label="Password"
                      type={showPassword ? 'text' : 'password'}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            size="large"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                      disabled={isSubmitting}
                      id="outlined-adornment-password-register"
                      {...field}
                      onChange={(e) => {
                        changePassword(e.target.value);
                        field.onChange(e);
                      }}
                    />
                  )}
                />
                {errors.password && (
                  <FormHelperText
                    error
                    id="standard-weight-helper-text-password-register"
                  >
                    {errors.password.message}
                  </FormHelperText>
                )}
              </FormControl>

              {strength !== 0 && (
                <FormControl fullWidth>
                  <Box sx={{ mb: 2 }}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item>
                        <Box
                          style={{ backgroundColor: level?.color }}
                          sx={{
                            width: 85,
                            height: 8,
                            borderRadius: `${theme.border.radius}`,
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <Typography variant="subtitle1" fontSize="0.75rem">
                          {level?.label}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </FormControl>
              )}

              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={privacyChecked}
                        onChange={(event) =>
                          setPrivacyChecked(event.target.checked)
                        }
                        name="checked"
                        color="primary"
                      />
                    }
                    label={
                      <Typography variant="subtitle1">
                        Agree with &nbsp;
                        <Typography
                          variant="subtitle1"
                          component={Link}
                          to={PrivacyPolicyPath}
                          sx={{ textDecoration: 'none' }}
                          color="primary"
                        >
                          Privacy Policy.
                        </Typography>
                      </Typography>
                    }
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={termsChecked}
                        onChange={(event) =>
                          setTermsChecked(event.target.checked)
                        }
                        name="checked"
                        color="primary"
                      />
                    }
                    label={
                      <Typography variant="subtitle1">
                        Agree with &nbsp;
                        <Typography
                          variant="subtitle1"
                          component={Link}
                          to={TermsAndConditionsPath}
                          sx={{ textDecoration: 'none' }}
                          color="primary"
                        >
                          Terms & Conditions.
                        </Typography>
                      </Typography>
                    }
                  />
                </Grid>
              </Grid>
              {errors.root && (
                <Box sx={{ mt: 3 }}>
                  <FormHelperText error>{errors.root.message}</FormHelperText>
                </Box>
              )}

              <Box display={'flex'} sx={{ mt: 2 }} justifyContent={'center'}>
                {!isSubmitting && (
                  <Button
                    fullWidth
                    disabled={!(termsChecked && privacyChecked)}
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Sign up
                  </Button>
                )}
                {isSubmitting && <CircularProgress />}
              </Box>
            </form>
          </Grid>
          <Grid item>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Grid item alignItems="center" xs={12}>
              <Typography
                component={Link}
                to="/auth/login"
                variant="subtitle1"
                color={'primary'}
                sx={{ textDecoration: 'none' }}
              >
                Already have an account?
              </Typography>
            </Grid>
          </Grid>
        </Fragment>
      )}
    </Grid>
  );
};

export default Signup;
