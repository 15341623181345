import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material';
import { DateTime } from 'luxon';
import { Dispatch, useContext, useState } from 'react';
import { BookingStatus, BookingStatusString } from '../../Model/Booking';
import { Event } from '../../Model/Event';
import { useCancelBookingMutation, useConfirmBookingMutation } from '../../Service/Events';
import { useGetMeQuery } from '../../Service/Me';
import { getErrorMessage } from '../../Util/Error';
import { ConfirmDialog } from '../Dialog/ConfirmDialog';
import { PermsContext, HasSitePerms, PermConfirmSiteBooking } from '../../Contexts/PermissionsContext';
export type Props = {
  event?: Event;
  open: boolean;
  onPaymentRequested?: Dispatch<Event | undefined>;
  setOpen: (open: boolean) => void;
};
export const EventDetailsDialog = (props: Props) => {
  const { data: me } = useGetMeQuery({});
  const isCancellable = me?.ID === props.event?.Booking?.BookerID;
  const siteID = props.event?.SiteID || 0;

  const [cancelBooking, { isLoading: isCancelling }] =
    useCancelBookingMutation();

  const [error, setError] = useState<string>();

  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);

  const [confirmBooking, { isLoading: isConfirming }] = useConfirmBookingMutation();

  const handleConfirmBooking = async () => {
    try {
      await confirmBooking(props.event as any).unwrap();
      props.setOpen(false);
    } catch (e) {
      const msg = getErrorMessage(e);
      setError(msg);
    }
  }

  const handleCancel = async () => {
    try {
      await cancelBooking(props.event as any).unwrap();
      props.setOpen(false);
    } catch (e) {
      const msg = getErrorMessage(e);
      setError(msg);
    }
  };

  const handleOpenConfirmDelete = () => {
    setOpenConfirmDelete(true);
  };

  const permissions = useContext(PermsContext).perms;

  const canConfirmBooking = HasSitePerms(permissions, siteID, PermConfirmSiteBooking);

  return (
    <Dialog
      maxWidth="xs"
      open={props.open}
      onClose={() => props.setOpen(false)}
    >
      <DialogTitle textAlign={'center'}>Event Details</DialogTitle>
      <DialogContent>
        <Grid
          container
          display={'flex'}
          alignItems={'center'}
          columnSpacing={2}
          rowSpacing={1}
        >
          <Grid item xs={4}>
            <Typography variant="subtitle1" fontWeight={'bold'}>
              Title
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography>{props.event?.Title}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle1" fontWeight={'bold'}>
              Booking ID
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography>{props.event?.Booking?.ID}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle1" fontWeight={'bold'}>
              Status
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography>
              {BookingStatusString(props.event?.Booking?.BookingStatus)}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle1" fontWeight={'bold'}>
              Start
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography>
              {DateTime.fromISO(props.event?.Start || '').toFormat(
                'EEE, dd MMM HH:mm'
              )}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle1" fontWeight={'bold'}>
              End
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography>
              {DateTime.fromISO(props.event?.End || '').toFormat(
                'EEE, dd MMM HH:mm'
              )}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle1" fontWeight={'bold'}>
              Court
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography>{props.event?.CourtName}</Typography>
          </Grid>
          <Grid item xs={12} textAlign={'center'} mt={2}>
            <Typography color={'error'} variant="subtitle1">
              {error}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {isCancellable && (
          <LoadingButton
            loading={isCancelling}
            onClick={handleOpenConfirmDelete}
            color="error"
          >
            Cancel Booking
          </LoadingButton>
        )}
        {props.event?.Booking?.BookingStatus === BookingStatus.PendingPayment &&
          canConfirmBooking && (
            <LoadingButton
              loading={isConfirming}
              onClick={handleConfirmBooking}
              color="primary"
            >
              Confirm Booking
            </LoadingButton>
          )

        }
        {props.event?.Booking?.BookingStatus === BookingStatus.PendingPayment &&
          props.onPaymentRequested && (
            <Button
              onClick={() => {
                if (!props.onPaymentRequested) {
                  return;
                }
                props.onPaymentRequested(props.event);
              }}
            >
              Make Payment
            </Button>
          )}
        <Button color="inherit" onClick={() => props.setOpen(false)}>
          Close
        </Button>
      </DialogActions>
      <ConfirmDialog
        title="Cancel Booking"
        message="Are you sure you want to cancel this booking? Any potential refunds will need to be made in-person."
        open={openConfirmDelete}
        setOpen={setOpenConfirmDelete}
        onConfirm={handleCancel}
        danger
      />
    </Dialog>
  );
};
