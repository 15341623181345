import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Snackbar,
  Typography,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { HelpTooltip } from '../../Components/UI/HelpTooltip';
import { SiteContext } from '../../Contexts/SiteContext';
import { SnackBarInfo } from '../../Model/SnackBarInfo';
import { useGetKBQuery } from '../../Service/KB';
import { useGetSiteQuery, useUpdateSiteMutation } from '../../Service/Site';
import { getErrorMessage } from '../../Util/Error';
export const EditSiteSettings = () => {
  const { data: kb } = useGetKBQuery();
  const schema = Yup.object().shape({
    ID: Yup.number().required('ID is required'),
    Name: Yup.string().max(50).required('Name is required'),
    Description: Yup.string().max(255),
    Address: Yup.string().max(255),
    City: Yup.string().max(50),
    Province: Yup.string().max(50),
    PostalCode: Yup.string().max(5),
    Phone: Yup.string().required('Phone is required'),
    Email: Yup.string().email('Invalid email').required('Email is required'),
    CountryID: Yup.number().required('Country is required'),
    CurrencyID: Yup.number().required('Currency is required'),
    Timezone: Yup.string().required('Timezone is required'),
    IsPrivate: Yup.boolean(),
    AllowAnonymousGuest: Yup.boolean(),
    IsActive: Yup.boolean(),
    DaysBeforeMembershipExpirationReminder: Yup.number().required().min(0),
    PendingBookingValidityPeriodMinutes: Yup.number().required().min(0),
    MinutesBeforePendingBookingsExpire: Yup.number().required().min(0),
  });

  const {
    control,
    handleSubmit,
    reset,
    setError,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: {
      ID: 0,
      Name: '',
      Description: '',
      Address: '',
      City: '',
      Province: '',
      PostalCode: '',
      Phone: '',
      CountryID: 0,
      Timezone: '',
      IsPrivate: false,
      AllowAnonymousGuest: false,
      CurrencyID: 0,
      Email: '',
      DaysBeforeMembershipExpirationReminder: 0,
      MinutesBeforePendingBookingsExpire: 0,
      PendingBookingValidityPeriodMinutes: 0,
      IsActive: true,
    },
    resolver: yupResolver(schema),
  });

  const siteCtx = useContext(SiteContext);

  const { data: site } = useGetSiteQuery(siteCtx.site.id ?? 0);

  useEffect(() => {
    if (site) {
      reset(site);
    }
  }, [site, reset]);

  const [updateSite, { isLoading: isUpdating }] = useUpdateSiteMutation();

  const [snackBarInfo, setSnackBarInfo] = useState<SnackBarInfo>({
    open: false,
    message: '',
    severity: 'success',
  });

  async function onSubmit(data: any) {
    const res = await updateSite(data);

    let info: SnackBarInfo = {
      open: true,
      message: 'Site updated successfully',
      severity: 'success',
    };
    if (res.error) {
      const msg = getErrorMessage(res.error);
      info = {
        open: true,
        message: 'Failed to update site: ' + msg,
        severity: 'error',
      };
      setError('root', { message: msg });
    }

    setSnackBarInfo(info);
  }

  return (
    <>
      <form id="editSiteSettings" noValidate onSubmit={handleSubmit(onSubmit)}>
        <Card
          sx={{
            marginX: 'auto',
            width: '500px',
          }}
        >
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6">Edit Site Settings</Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth error={!!errors.Name}>
                  <InputLabel htmlFor="name">Name</InputLabel>
                  <Controller
                    name="Name"
                    control={control}
                    render={({ field }) => (
                      <OutlinedInput
                        {...field}
                        id="name"
                        label="Name"
                        type="text"
                        disabled={isUpdating}
                        autoComplete="first-name"
                      />
                    )}
                  />
                  <FormHelperText error>{errors.Name?.message}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth error={!!errors.Description}>
                  <InputLabel htmlFor="description">Description</InputLabel>
                  <Controller
                    name="Description"
                    control={control}
                    render={({ field }) => (
                      <OutlinedInput
                        {...field}
                        id="description"
                        label="Description"
                        type="text"
                        disabled={isUpdating}
                        autoComplete="description"
                      />
                    )}
                  />
                  <FormHelperText error>
                    {errors.Description?.message}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth error={!!errors.Address}>
                  <InputLabel htmlFor="address">Address</InputLabel>
                  <Controller
                    name="Address"
                    control={control}
                    render={({ field }) => (
                      <OutlinedInput
                        {...field}
                        id="address"
                        label="Address"
                        type="text"
                        disabled={isUpdating}
                        autoComplete="address"
                      />
                    )}
                  />
                  <FormHelperText error>
                    {errors.Address?.message}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth error={!!errors.City}>
                  <InputLabel htmlFor="city">City</InputLabel>
                  <Controller
                    name="City"
                    control={control}
                    render={({ field }) => (
                      <OutlinedInput
                        {...field}
                        id="city"
                        label="City"
                        type="text"
                        disabled={isUpdating}
                        autoComplete="city"
                      />
                    )}
                  />
                  <FormHelperText error>{errors.City?.message}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth error={!!errors.Province}>
                  <InputLabel htmlFor="province">Province</InputLabel>
                  <Controller
                    name="Province"
                    control={control}
                    render={({ field }) => (
                      <OutlinedInput
                        {...field}
                        id="province"
                        label="Province"
                        type="text"
                        disabled={isUpdating}
                        autoComplete="province"
                      />
                    )}
                  />
                  <FormHelperText error>
                    {errors.Province?.message}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth error={!!errors.PostalCode}>
                  <InputLabel htmlFor="postalCode">Postal Code</InputLabel>
                  <Controller
                    name="PostalCode"
                    control={control}
                    render={({ field }) => (
                      <OutlinedInput
                        {...field}
                        id="postalCode"
                        label="Postal Code"
                        type="text"
                        disabled={isUpdating}
                        autoComplete="postalCode"
                      />
                    )}
                  />
                  <FormHelperText error>
                    {errors.PostalCode?.message}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth error={!!errors.CountryID}>
                  <InputLabel htmlFor="countryID">Country</InputLabel>
                  <Controller
                    name="CountryID"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        value={field.value || ''}
                        id="countryID"
                        label="Country"
                        disabled={isUpdating}
                      >
                        {kb?.Countries.map((country) => (
                          <MenuItem key={country.ID} value={country.ID}>
                            {country.Name}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                  <FormHelperText error>
                    {errors.CountryID?.message}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth error={!!errors.CurrencyID}>
                  <InputLabel htmlFor="countryID">Currency</InputLabel>
                  <Controller
                    name="CurrencyID"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        value={field.value || ''}
                        id="currencyID"
                        label="Currency"
                        disabled={isUpdating}
                      >
                        {kb?.Currencies.map((c) => (
                          <MenuItem key={c.ID} value={c.ID}>
                            {c.Name}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                  <FormHelperText error>
                    {errors.CurrencyID?.message}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth error={!!errors.Timezone}>
                  <InputLabel htmlFor="timezone">Timezone</InputLabel>
                  <Controller
                    name="Timezone"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        id="timezone"
                        label="Timezone"
                        value={field.value || ''}
                        disabled={isUpdating}
                      >
                        {kb?.TimeZones.map((timezone) => (
                          <MenuItem key={timezone} value={timezone}>
                            {timezone}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                  <FormHelperText error>
                    {errors.Timezone?.message}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth error={!!errors.Phone}>
                  <InputLabel htmlFor="phoneNumber">Phone Number</InputLabel>
                  <Controller
                    name="Phone"
                    control={control}
                    render={({ field }) => (
                      <OutlinedInput
                        {...field}
                        id="phone"
                        label="Phone Number"
                        type="text"
                        disabled={isUpdating}
                        autoComplete="phoneNumber"
                      />
                    )}
                  />
                  <FormHelperText error>{errors.Phone?.message}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth error={!!errors.Email}>
                  <InputLabel htmlFor="email">Email</InputLabel>
                  <Controller
                    name="Email"
                    control={control}
                    render={({ field }) => (
                      <OutlinedInput
                        {...field}
                        id="email"
                        label="Email"
                        type="text"
                        disabled={isUpdating}
                        autoComplete="email"
                      />
                    )}
                  />
                  <FormHelperText error>{errors.Email?.message}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth error={!!errors.IsPrivate}>
                  <FormControlLabel
                    name="IsPrivate"
                    label="Is Private"
                    control={
                      <Controller
                        name="IsPrivate"
                        control={control}
                        render={({ field }) => (
                          <Checkbox
                            {...field}
                            id="isPrivate"
                            checked={field.value || false}
                            disabled={isUpdating}
                          />
                        )}
                      />
                    }
                  />
                  <FormHelperText error>
                    {errors.IsPrivate?.message}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth error={!!errors.AllowAnonymousGuest}>
                  <FormControlLabel
                    name="AllowAnonymousGuest"
                    label="Allow Anonymous Guest"
                    control={
                      <Controller
                        name="AllowAnonymousGuest"
                        control={control}
                        render={({ field }) => (
                          <Checkbox
                            {...field}
                            id="allowAnonymousGuest"
                            checked={field.value || false}
                            disabled={isUpdating}
                          />
                        )}
                      />
                    }
                  />
                  <FormHelperText error>
                    {errors.AllowAnonymousGuest?.message}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl
                  fullWidth
                  error={!!errors.DaysBeforeMembershipExpirationReminder}
                >
                  <InputLabel htmlFor="daysBeforeMembershipExpirationReminder">
                    Membership Exp. Reminder
                  </InputLabel>
                  <Controller
                    name="DaysBeforeMembershipExpirationReminder"
                    control={control}
                    render={({ field }) => (
                      <HelpTooltip text="This value determines how many days before the membership expiration a reminder will be sent. If set to 0 no reminder will be sent.">
                        <OutlinedInput
                          {...field}
                          id="daysBeforeMembershipExpirationReminder"
                          label="Membership Exp. Reminder"
                          type="number"
                          fullWidth
                          inputProps={{ min: 0, max: 60 }}
                          disabled={isUpdating}
                          endAdornment={
                            <InputAdornment position="end">days</InputAdornment>
                          }
                          autoComplete="daysBeforeMembershipExpirationReminder"
                        />
                      </HelpTooltip>
                    )}
                  />
                  <FormHelperText error>
                    {errors.DaysBeforeMembershipExpirationReminder?.message}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl
                  fullWidth
                  error={!!errors.MinutesBeforePendingBookingsExpire}
                >
                  <InputLabel htmlFor="minutesBeforePendingBookingsExpire">
                    Pending Booking Expiration
                  </InputLabel>
                  <Controller
                    name="MinutesBeforePendingBookingsExpire"
                    control={control}
                    render={({ field }) => (
                      <HelpTooltip text="This value determines how many minutes before a 'pending' booking is scheduled to start where the system will cancel the booking. If set to 0 no cancellation will occur.">
                        <OutlinedInput
                          {...field}
                          id="minutesBeforePendingBookingsExpire"
                          label="Pending Booking Expiration"
                          type="number"
                          fullWidth
                          inputProps={{ min: 0, max: 60 }}
                          disabled={isUpdating}
                          endAdornment={
                            <InputAdornment position="end">
                              minutes
                            </InputAdornment>
                          }
                          autoComplete="minutesBeforePendingBookingsExpire"
                        />
                      </HelpTooltip>
                    )}
                  />
                  <FormHelperText error>
                    {errors.MinutesBeforePendingBookingsExpire?.message}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl
                  fullWidth
                  error={!!errors.MinutesBeforePendingBookingsExpire}
                >
                  <InputLabel htmlFor="pendingBookingValidityPeriodMinutes">
                    Pending Booking Validity Per.
                  </InputLabel>
                  <Controller
                    name="PendingBookingValidityPeriodMinutes"
                    control={control}
                    render={({ field }) => (
                      <HelpTooltip text="This value determines how many minutes can elapse before a 'pending' booking is considered invalid and is cancelled. If set to 0 no cancellation will occur.">
                        <OutlinedInput
                          {...field}
                          id="pendingBookingValidityPeriodMinutes"
                          label="Pending Booking Validity Per."
                          type="number"
                          fullWidth
                          inputProps={{ min: 0, max: 60 }}
                          disabled={isUpdating}
                          endAdornment={
                            <InputAdornment position="end">
                              minutes
                            </InputAdornment>
                          }
                          autoComplete="pendingBookingValidityPeriodMinutes"
                        />
                      </HelpTooltip>
                    )}
                  />
                  <FormHelperText error>
                    {errors.PendingBookingValidityPeriodMinutes?.message}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Typography color={'error'}>{errors.root?.message}</Typography>
              </Grid>
            </Grid>
          </CardContent>
          <CardActions sx={{ justifyContent: 'center' }}>
            <LoadingButton
              disabled={!isDirty}
              loading={isUpdating}
              type="submit"
            >
              Update
            </LoadingButton>
          </CardActions>
        </Card>
      </form>
      <Snackbar
        open={snackBarInfo.open}
        autoHideDuration={6000}
        onClose={() => setSnackBarInfo({ ...snackBarInfo, open: false })}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setSnackBarInfo({ ...snackBarInfo, open: false })}
          severity={snackBarInfo.severity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackBarInfo.message}
        </Alert>
      </Snackbar>
    </>
  );
};
