import { createApi } from '@reduxjs/toolkit/query/react';
import { UserCredit } from '../Model/UserCredit';
import { baseQuery } from './BaseQuery';
import { ErrorTags, getErrorTag } from './Error';

export type GetUserCreditRequest = {
  SiteID: number;
  UserSearch: string;
};

export type AddUserCreditRequest = {
  SiteID: number;
  UserID: number;
  Amount: number;
  CurrencyID: number;
};

export type EditUserCreditRequest = {
  SiteID: number;
  ID: number;
  Amount: number;
};

export type DeleteUserCreditRequest = {
  SiteID: number;
  ID: number;
};

export const userCreditsApi = createApi({
  reducerPath: 'userCreditsApi',
  baseQuery: baseQuery,
  tagTypes: [...ErrorTags, 'UserCredits'],
  endpoints: (build) => ({
    getSiteUserCredits: build.query<UserCredit[], GetUserCreditRequest>({
      query: (req: GetUserCreditRequest) => ({
        url: `/sites/${req.SiteID}/user-credits?q=${req.UserSearch}`,
        method: 'GET',
      }),
      providesTags: (result, error) => {
        if (error?.status) {
          return getErrorTag(error?.status);
        }
        return result
          ? [
              ...result.map(({ ID }) => ({ type: 'UserCredits', id: ID })),
              { type: 'UserCredits', id: 'LIST' },
            ]
          : [{ type: 'UserCredits', id: 'LIST' }];
      },
    }),
    addUserCredit: build.mutation<UserCredit, AddUserCreditRequest>({
      query: (uc: AddUserCreditRequest) => ({
        url: `/sites/${uc.SiteID}/user-credits`,
        method: 'POST',
        body: uc,
      }),
      invalidatesTags: (result) => [
        { type: 'UserCredits', id: result?.ID },
        { type: 'UserCredits', id: 'LIST' },
      ],
    }),
    editUserCredit: build.mutation<UserCredit, EditUserCreditRequest>({
      query: (req: UserCredit) => ({
        url: `/sites/${req.SiteID}/user-credits/${req.ID}`,
        method: 'PUT',
        body: req,
      }),
      invalidatesTags: (result) => [
        { type: 'UserCredits', id: result?.ID },
        { type: 'UserCredits', id: 'LIST' },
      ],
    }),
    deleteUserCredit: build.mutation<void, UserCredit>({
      query: (req: UserCredit) => ({
        url: `/sites/${req.SiteID}/user-credits/${req.ID}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, _error, arg) => [
        { type: 'UserCredits', id: arg.ID },
        { type: 'UserCredits', id: 'LIST' },
      ],
    }),
  }),
});

export const {
  useGetSiteUserCreditsQuery,
  useAddUserCreditMutation,
  useEditUserCreditMutation,
  useDeleteUserCreditMutation,
} = userCreditsApi;
