import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { useContext, useEffect } from 'react';
import {
  Controller,
  FieldValues,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { NumericFormat } from 'react-number-format';
import * as Yup from 'yup';
import { SiteContext } from '../../../Contexts/SiteContext';
import { UserCredit } from '../../../Model/UserCredit';
import { useGetKBQuery } from '../../../Service/KB';
import { useGetSiteQuery } from '../../../Service/Site';
import { useEditUserCreditMutation } from '../../../Service/UserCredit';
import { getErrorMessage } from '../../../Util/Error';

export type Props = {
  open: boolean;
  onClose: () => void;
  userCredit?: UserCredit;
};
export const EditUserCreditDialog = (props: Props) => {
  const schema = Yup.object().shape({
    Amount: Yup.number().required().min(1),
  });

  const {
    control,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      Amount: 0,
    },
    resolver: yupResolver(schema),
  });

  const siteID = useContext(SiteContext).site.id;
  const { data: site } = useGetSiteQuery(siteID);
  const { data: kb } = useGetKBQuery();
  const currency = kb?.Currencies.find((c) => c.ID === site?.CurrencyID);

  useEffect(() => {
    if (!props.open) {
      return;
    }

    reset({
      Amount: props.userCredit?.Amount,
    });
  }, [props.open, props.userCredit, reset]);

  const [editUserCredit] = useEditUserCreditMutation();

  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    try {
      await editUserCredit({
        Amount: data.Amount,
        SiteID: siteID,
        ID: props.userCredit?.ID || 0,
      }).unwrap();
      props.onClose();
    } catch (e: any) {
      const msg = getErrorMessage(e);
      setError('root', { message: msg });
    }
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      aria-label="edit-user-credit-dialog"
    >
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <DialogTitle textAlign={'center'}>Edit User Credit</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} direction="column">
            <Grid item xs={12} textAlign={'center'} my={2}>
              <Typography variant="body1">
                {props.userCredit?.FirstName} {props.userCredit?.LastName}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl error={!!errors.Amount}>
                <Controller
                  control={control}
                  name="Amount"
                  render={({ field }) => (
                    <NumericFormat
                      label="Amount"
                      value={field.value}
                      onValueChange={(values) => {
                        field.onChange(values.floatValue);
                      }}
                      customInput={TextField}
                      thousandSeparator
                      allowNegative={false}
                      decimalScale={2}
                      fixedDecimalScale
                      allowLeadingZeros
                      prefix={currency?.Symbol + ' '}
                    />
                  )}
                />
                <FormHelperText error>{errors.Amount?.message}</FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <LoadingButton type="submit">Save</LoadingButton>
          <Button color="inherit" onClick={props.onClose}>
            Close
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
