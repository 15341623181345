import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab } from '@mui/material';
import { useContext, useState } from 'react';
import {
  HasSitePaymentProviderAdminPerms,
  HasSiteUserCreditAdminPerms,
  PermsContext,
} from '../../Contexts/PermissionsContext';
import { SiteContext } from '../../Contexts/SiteContext';
import { Providers } from './Providers/Providers';
import { UserCredits } from './UserCredits/UserCredits';

export const ManagePaymentsPage = () => {
  const [tab, setTab] = useState('1');

  const perms = useContext(PermsContext).perms;
  const siteID = useContext(SiteContext).site.id;
  return (
    <TabContext value={tab}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <TabList onChange={(e, value) => setTab(value)}>
          {HasSitePaymentProviderAdminPerms(perms, siteID) && (
            <Tab label="Providers" value="1" />
          )}
          {HasSiteUserCreditAdminPerms(perms, siteID) && (
            <Tab label="Credits" value="2" />
          )}
        </TabList>
      </Box>
      {HasSitePaymentProviderAdminPerms(perms, siteID) && (
        <TabPanel value="1">
          <Providers />
        </TabPanel>
      )}
      {HasSiteUserCreditAdminPerms(perms, siteID) && (
        <TabPanel value="2">
          <UserCredits />
        </TabPanel>
      )}
    </TabContext>
  );
};
