import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { SetStateAction } from 'react';

export interface InformationalDialogProps {
  open: boolean;
  setOpen: React.Dispatch<SetStateAction<boolean>>;
  title: string;
  message: string;
}

export const InformationalDialog = (props: InformationalDialogProps) => {
  return (
    <Dialog
      open={props.open}
      keepMounted
      onClose={() => props.setOpen(false)}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {props.message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={() => props.setOpen(false)}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};
