import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
} from '@mui/material';
import { useContext } from 'react';
import {
  Controller,
  FieldValues,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { NumericFormat } from 'react-number-format';
import * as Yup from 'yup';
import { UserSearchInput } from '../../../Components/UI/UserSearchInput';
import { SiteContext } from '../../../Contexts/SiteContext';
import { useGetKBQuery } from '../../../Service/KB';
import { useGetSiteQuery } from '../../../Service/Site';
import { useAddUserCreditMutation } from '../../../Service/UserCredit';
import { getErrorMessage } from '../../../Util/Error';

export type Props = {
  open: boolean;
  onClose: () => void;
};

export const AddUserCreditDialog = (props: Props) => {
  const schema = Yup.object().shape({
    User: Yup.object().shape({
      ID: Yup.number().required(),
      Email: Yup.string().email('Invalid email').required('Email is required'),
      FirstName: Yup.string().required('First Name is required'),
      LastName: Yup.string().required('Last Name is required'),
      SiteIDs: Yup.array().of(Yup.number().required()).required().min(0),
    }),
    Amount: Yup.number().required().min(1),
  });

  const siteID = useContext(SiteContext).site.id;
  const { data: site } = useGetSiteQuery(siteID);
  const { data: kb } = useGetKBQuery();

  const currency = kb?.Currencies.find((c) => c.ID === site?.CurrencyID);

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      Amount: 0,
    },
    resolver: yupResolver(schema),
  });

  const [addUserCredit] = useAddUserCreditMutation();

  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    try {
      await addUserCredit({
        Amount: data.Amount,
        CurrencyID: site?.CurrencyID || 0,
        SiteID: siteID,
        UserID: data.User.ID,
      }).unwrap();
      props.onClose();
    } catch (e: any) {
      const msg = getErrorMessage(e);
      setError('root', { message: msg });
    }
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      aria-label="add-user-credit"
      fullWidth
    >
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Add User Credit</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} my={2}>
            <Grid item xs={12}>
              <FormControl fullWidth error={!!errors.User?.ID}>
                <Controller
                  name="User"
                  control={control}
                  render={({ field }) => (
                    <UserSearchInput
                      siteId={siteID}
                      includeSelf
                      value={field.value}
                      onChange={field.onChange}
                      label="User"
                    />
                  )}
                />
              </FormControl>
              <FormHelperText error>{errors.User?.ID?.message}</FormHelperText>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth error={!!errors.Amount}>
                <Controller
                  name="Amount"
                  control={control}
                  render={({ field }) => (
                    <NumericFormat
                      label="Amount"
                      value={field.value}
                      onValueChange={(values) => {
                        field.onChange(values.floatValue);
                      }}
                      decimalScale={2}
                      decimalSeparator=","
                      thousandSeparator="."
                      prefix={currency?.Symbol}
                      allowNegative={false}
                      customInput={TextField}
                    />
                  )}
                />
              </FormControl>
              <FormHelperText error>{errors.Amount?.message}</FormHelperText>
            </Grid>
            <Grid item xs={12}>
              <FormHelperText error>{errors.root?.message}</FormHelperText>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <LoadingButton type="submit">Add</LoadingButton>
          <Button color="inherit" onClick={props.onClose}>
            Close
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
