import { createApi } from '@reduxjs/toolkit/query/react';
import { DateTime } from 'luxon';
import { BookingRequest } from '../Model/Booking';
import { Event } from '../Model/Event';
import { baseQuery } from './BaseQuery';
import { ErrorTags, getErrorTag } from './Error';

export type GetEventsParams = {
  siteID: number;
  startMillis?: number;
  endMillis?: number;
};

export type CancelBookingParams = {
  siteID: number;
  bookingID: number;
};

export type BlockCourtRequest = {
  SiteID: number;
  CourtID: number;
  GameTypeID: number;
  SlotIDs: number[];
  RepeatUntil: Date;
  EventTypeID: number;
  CancelExistingBookings: boolean;
};

export const eventsApi = createApi({
  reducerPath: 'eventsApi',
  baseQuery,
  tagTypes: [...ErrorTags, 'Events', 'Bookings', 'UserCredits'],
  endpoints: (build) => ({
    getEvents: build.query<Event[], GetEventsParams>({
      query: (p: GetEventsParams) =>
        `/sites/${p.siteID}/events?start=${p.startMillis}&end=${p.endMillis}`,
      providesTags: (result, error) => {
        if (error?.status) {
          return getErrorTag(error?.status);
        }

        return result
          ? [
            ...result.map((event) => ({
              type: 'Events',
              id: `${event.SlotID}-${DateTime.fromISO(
                event.Start as unknown as string
              ).toMillis()}`,
            })),
          ]
          : [];
      },
    }),
    bookSlot: build.mutation<
      Event,
      {
        siteID: number;
        courtID: number;
        slotID: number;
        request: BookingRequest;
      }
    >({
      query: ({ siteID, courtID, slotID, request }) => ({
        url: `/sites/${siteID}/courts/${courtID}/slots/${slotID}/bookings`,
        method: 'POST',
        body: request,
      }),
      invalidatesTags: [{ type: 'Events', id: 'List' }, { type: 'UserCredits', id: 'List' }],
    }),
    cancelBooking: build.mutation<void, Event>({
      query: (e: Event) => ({
        url: `/sites/${e.SiteID}/courts/${e.CourtID}/slots/${e.SlotID}/bookings/${e.Booking?.ID}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'Events', id: 'List' }],
    }),
    confirmBooking: build.mutation<void, Event>({
      query: (e: Event) => ({
        url: `/sites/${e.SiteID}/courts/${e.CourtID}/slots/${e.SlotID}/bookings/${e.Booking?.ID}/confirm`,
        method: 'POST',
      }),
      invalidatesTags: [{ type: 'Events', id: 'List' }],
    }),
    getMyBookings: build.query<Event[], number>({
      query: (siteID: number) => `/sites/${siteID}/my-bookings`,
      providesTags: (result, error) => {
        if (error?.status) {
          return getErrorTag(error?.status);
        }

        if (result) {
          return [{ type: 'Events', id: 'List' }];
        }

        return [];
      },
    }),
    blockCourt: build.mutation<void, BlockCourtRequest>({
      query: (bc: BlockCourtRequest) => ({
        url: `/sites/${bc.SiteID}/courts/${bc.CourtID}/block`,
        method: 'POST',
        body: bc,
      }),
      invalidatesTags: ['Events'],
    }),
  }),
});

export const {
  useGetEventsQuery,
  useBookSlotMutation,
  useCancelBookingMutation,
  useGetMyBookingsQuery,
  useBlockCourtMutation,
  useConfirmBookingMutation,
} = eventsApi;
